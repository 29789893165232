import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { endOfWeek, format, startOfWeek } from 'date-fns';

import { FaChevronCircleLeft } from 'react-icons/fa';

import DatePicker from '../../../components/DatePicker';

import api from '../../../services/api';

import {
  Container,
  Title,
  Buttons,
  Filters,
  FiltersLine,
  FiltersFieldBetween,
  Button,
} from '../../../styles/lists';

import FilterButton from '../../../components/FilterButton';
import { Clients, Collaborators, Descriptions, List1, List1Collaborators, List1TotalAndType, List2, List2Clients, List2Descriptions, Lists, Total, Type, TypeList } from './styles';

interface RegisterParams {
  total: number;
  perCollaborator: any[],
  perType: any[],
  rankingClients: any[],
  rankingDescriptions: any[]
}

const CustomerServiceReport: React.FC = () => {
  const navigate = useNavigate();

  const [registers, setRegisters] = useState<RegisterParams | null>(null);
  const [dataInicial, setDataIncial] = useState<Date | null>(startOfWeek(new Date()));
  const [dataFinal, setDataFinal] = useState<Date | null>(endOfWeek(new Date()));

  useEffect(() => {
    loadRegisters();
  }, []);

  async function loadRegisters() {
    try {
      const response = await api.get('/customerServices/report', {
        params: {
          dataInicial: dataInicial && format(dataInicial, 'yyyy-MM-dd'),
          dataFinal: dataFinal && format(dataFinal, 'yyyy-MM-dd'),
        },
      });
      setRegisters(response.data);
    } catch (err) {
      setRegisters(null);
    }
  }


  function handleCustomerServices() {
    navigate('/customerServices');
  }

  return (
    <Container>
      <Title>
        <h1>Estatísticas do atendimento</h1>
      </Title>
      <Buttons>
        <Button visual="secondary" onClick={handleCustomerServices}>
          <FaChevronCircleLeft size={18} color="#1362f5" />
          Voltar
        </Button>
      </Buttons>
      <Filters>
        <FiltersLine>
          <FiltersFieldBetween size="20%">
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataIncial(e);
                }}
                placeholderText="Data inicial"
                selected={dataInicial}
              />
            </div>
            <h3>a</h3>
            <div>
              <DatePicker
                error={false}
                onChange={(e: any) => {
                  setDataFinal(e);
                }}
                placeholderText="Data final"
                selected={dataFinal}
              />
            </div>
          </FiltersFieldBetween>
          <FilterButton
              tooltipDescription='Filtrar Registros'
              onClick={loadRegisters}
            />
        </FiltersLine>
      </Filters>
      
      <Lists>
        <List1>
          
          <List1TotalAndType>
          
            <h2>Totalizadores</h2>

            <Total>
              <h3>{registers?.total} atendimentos</h3>
            </Total>
            <Type>
            {
              registers?.perType
                .map((e, index) => (
                  <TypeList key={index}>
                    <text>{e.count}</text>
                    <text>{e.tipo}</text>
                  </TypeList>
                ))}
            </Type>
          </List1TotalAndType>

          <List1Collaborators>
            <h2>Ranking de Colaboradores</h2>
            <Collaborators>
            {
              registers?.perCollaborator
                .map((e, index) => (
                  <TypeList key={index}>
                    <text>{e.count}</text>
                    <text>{e.nome}</text>
                  </TypeList>
                ))}
            </Collaborators>
          </List1Collaborators>
        </List1>

        <List2>
          <List2Clients>
            <h2>Ranking de clientes</h2>
            <Clients>
            {
              registers?.rankingClients
                .map((e, index) => (
                  <TypeList key={index}>
                    <text>{e.count}</text>
                    <text>{e.fantasia}</text>
                  </TypeList>
                ))}
            </Clients>
          </List2Clients>
          <List2Descriptions>
            <h2>Ranking de descrições</h2>
            <Descriptions>
            {
              registers?.rankingDescriptions
                .map((e, index) => (
                  <TypeList key={index}>
                    <text>{e.count}</text>
                    <text>{e.descricao}</text>
                  </TypeList>
                ))}
            </Descriptions>
          </List2Descriptions>
        </List2>
      </Lists>

    </Container>
  );
};

export default CustomerServiceReport;
