import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiCornerDownLeft } from 'react-icons/fi';
import Switch from 'react-switch';

import api from '../../../services/api';

import Confirmation from '../../../components/Confirmation';
import {
  Container,
  Title,
  Form,
  FormBlock,
  FormLine,
  FormField,
  FormTitle,
  FormButtons,
  Button,
  FormFieldTextArea,
  FormFieldInput,
} from '../../../styles/registers';
import { manipulateDateHour } from '../../../utils/handleLists';

import { changeForm, saveForm, loadForm } from '../../../utils/handleForms';

interface EntityData {
  dataHora: string;
  produto: string;
  produtoVersao: string;
  cliente: string;
  descricao: string;
  visualizado: number;
}
const FailureRegister: React.FC = () => {

  const navigate = useNavigate();
  const { idFailure } = useParams<{ idFailure: string }>();

  const [entity, setEntity] = useState<EntityData>({
    dataHora: '',
    produto: '',
    produtoVersao: '',
    cliente: '',
    descricao: '',
    visualizado: 0,
  });

  useEffect(() => {
    async function loadEntity() {
      if (idFailure !== undefined) {
        const response = await api.get(`/failures`, {
          params: {
            id: idFailure
          }
        });

        if (response.data.length > 0) {
          console.log(response.data[0])
          const failureData = response.data[0];

          setEntity({
            dataHora: failureData.dataHora && manipulateDateHour(failureData.dataHora),
            produto: failureData.produtoDados.descricao,
            produtoVersao: failureData.produtoVersaoDados.versao,
            cliente: failureData.clienteDados.fantasia,
            descricao: failureData.produtoDados.descricao == 'EASY CLOUD' ? JSON.stringify(JSON.parse(failureData.descricao), null, 2) : failureData.descricao,
            visualizado: failureData.visualizado,
          });
        }
      }
    }
    loadEntity();
  }, [idFailure]);

  function handleChanges(id: string, value: any) {
    const newEntity = changeForm(entity, id, value);
    setEntity((newEntity as unknown) as EntityData);
  }

  return (
    <Container>
      <Title>
        <h1>Cadastro de Ocorrência de Falhas</h1>
      </Title>
      <Form>
        <FormBlock>
          <FormTitle>DADOS</FormTitle>
          <FormLine>
            <FormField size="15%">
              <span>Data/Hora</span>
              <FormFieldInput
                value={entity.dataHora}
                autoComplete="off"
                id="dataHora"
              />
            </FormField>

            <FormField size="30%">
              <span>Produto</span>
              <FormFieldInput
                value={entity.produto}
                autoComplete="off"
                id="produto"
              />
            </FormField>

            <FormField size="10%">
              <span>Versão</span>
              <FormFieldInput
                value={entity.produtoVersao}
                autoComplete="off"
                id="produtoVersao"
              />
            </FormField>

            <FormField size="40%">
              <span>Cliente</span>
              <FormFieldInput
                value={entity.cliente}
                autoComplete="off"
                id="cliente"
              />
            </FormField>

            <FormField size="5%">
              <span>Visualizado</span>
              <Switch
                checked={!!entity.visualizado}
                onChange={() => {
                  handleChanges(
                    'visualizado',
                    entity.visualizado === 0 ? 1 : 0,
                  );
                }}
                disabled={true}
              />
            </FormField>

          </FormLine>

          <FormLine height="250px">
            <FormField size="100%">
              <span>Descrição</span>
              <FormFieldTextArea
                height="210px"
                value={entity.descricao}
                autoComplete="off"
                id="descricao"
                placeholder="Digite a descrição"
              />
            </FormField>
          </FormLine>
        </FormBlock>

        <FormButtons>
          <Button
            visual="secondary"
            type="button"
            onClick={
              () => navigate(-1)
            }
          >
            <FiCornerDownLeft size={20} color="#1362f5" />
            Voltar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  );
};

export default FailureRegister;
