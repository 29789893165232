import styled from 'styled-components';

export const Lists = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 12px;
`; 

export const List1 = styled.div`
  display: flex;
  flex-direction: row;
  height: 400px;
  max-height: 400px;
  margin: 10px 20px;
`;

export const List1TotalAndType = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  height: 100%;
  border-radius: 10px;
  margin-right: 1%;
  background: #262424;
  padding: 16px;
  overflow: auto;

  h2 {
    margin-bottom: 24px;
    color: #1362f5;
  }

`;

export const Total = styled.div`
  width: 100%;
  height: 60px;
  //background: #2e2c2c;
  display: flex;
  padding-left: 8px;
  border-radius: 8px;
  align-items: center;
  h3 {
    color: #d1cdcb;
  }
`;

export const Type = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  border-radius: 8px;
  justify-content: center;
  text {
    color: #d1cdcb;
    margin-right: 8px;
    font-weight: bold;
  }
`;

export const TypeList = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const List1Collaborators = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  border-radius: 10px;
  background: #262424;
  padding: 16px;

  h2 {
    margin-bottom: 24px;
    color: #1362f5;
  }

`;

export const Collaborators = styled.div`
  
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  border-radius: 8px;
  justify-content: center;
  text {
    font-size: 18px;
    color: #d1cdcb;
    margin-right: 8px;
    font-weight: bold;
  }
`;

export const List2 = styled.div`
  display: flex;
  flex-direction: row;
  height: 800px;
  max-height: 800px;
  margin: 10px 20px;
`;

export const List2Clients = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  height: 100%;
  border-radius: 10px;
  margin-right: 1%;
  background: #262424;
  padding: 16px;
  overflow: auto;
  h2 {
    margin-bottom: 24px;
    color: #1362f5;
  }
`;

export const Clients = styled.div`
  
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  border-radius: 8px;
  justify-content: center;
  text {
    font-size: 18px;
    color: #d1cdcb;
    margin-right: 8px;
    font-weight: bold;
  }
`;

export const List2Descriptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  border-radius: 10px;
  background: #262424;
  padding: 16px;
  overflow: auto;
  h2 {
    margin-bottom: 24px;
    color: #1362f5;
  }
`;

export const Descriptions = styled.div`
  
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  border-radius: 8px;
  justify-content: center;
  text {
    font-size: 18px;
    color: #d1cdcb;
    margin-right: 8px;
    font-weight: bold;
  }
`;
